<script setup lang="ts">
import NoNotifications from "@/assets/NoNotifications.vue";
import NotificationSidebarItem from "@/features/NotificationSidebar/NotificationSidebarItem.vue";
import { useNotificationSidebar } from "@/features/NotificationSidebar/useNotificationSidebar";
import { useI18n } from "vue-i18n";

defineOptions({
  name: "NotificationSidebar",
});

const { t } = useI18n();
const {
  getNotifications,
  readNotifications,
  unreadNotifications,
  toggleNotificationSidebar,
  showNotificationSidebar,
} = useNotificationSidebar();

getNotifications();
</script>

<template>
  <div v-if="showNotificationSidebar" class="notification-sidebar">
    <div class="container">
      <header class="header">
        <div class="top">
          <h1 class="title">
            {{ t("notifications.title") }}
          </h1>
          <FontAwesomeIcon
            :icon="['fal', 'times']"
            class="icon"
            @click="toggleNotificationSidebar"
          />
        </div>
      </header>
      <transition-group class="notifications" name="notification" tag="ul">
        <li key="unread" class="list-header-unread">
          {{ t("notifications.unread") }}
        </li>
        <NotificationSidebarItem
          v-for="notification in unreadNotifications"
          :key="notification.id"
          :notification="notification"
        />
        <NoNotifications
          v-if="unreadNotifications.length === 0"
          :text="t('notifications.no_unread_notifications')"
        />
        <li v-if="readNotifications.length" key="read" class="list-header-read">
          {{ t("notifications.read") }}
        </li>
        <NotificationSidebarItem
          v-for="notification in readNotifications"
          :key="notification.id"
          :notification="notification"
          read
        />
      </transition-group>
    </div>
    <div class="backdrop" @click="toggleNotificationSidebar" />
  </div>
</template>

<style lang="scss" scoped>
.notification-sidebar {
  position: fixed;
  top: 0;
  left: 90px;
  display: flex;
  height: 100%;
  z-index: 50;

  .container {
    box-shadow: 5px 0 15px -9px;
    padding: 15px;
    background-color: var(--background);
    min-width: 304px;
    overflow-y: auto;
  }

  .backdrop {
    flex: 1;
    cursor: pointer;
  }

  .header {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--text);

    .top {
      display: flex;

      .title {
        flex: 1;
        font-weight: 700;
        font-size: 20px;
        color: var(--text);
      }

      .icon {
        font-size: 25px;
        font-weight: 300;
        color: var(--text);
        cursor: pointer;
      }
    }
  }

  .notifications {
    .list-header-unread,
    .list-header-read {
      font-weight: 700;
      font-size: 11px;
      color: var(--text);
      transition: all 150ms ease-in;
      transform-origin: left;
      text-transform: uppercase;
    }

    .list-header-unread {
      margin-bottom: 10px;
    }

    .list-header-read {
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .notification-enter-from,
    .notification-leave-to {
      transform: translateX(-394px);
    }

    .notification-leave-active {
      position: absolute;
    }

    .notification-move {
      transition: all 150ms ease-in;
      transition-delay: 150ms;
    }

    & > li {
      transition: all 150ms ease-in;
      transform-origin: left;
    }
  }
}
</style>
