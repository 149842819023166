import { createApp } from "vue";
import App from "../App.vue";
import i18n from "./plugin/i18n";
import router from "./plugin/router";

const app = createApp(App);

app.use(router);
app.use(i18n);

export default app;
