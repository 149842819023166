import bg from "./bg";
import cs from "./cs";
import de from "./de";
import en from "./en";
import es from "./es";
import fr from "./fr";
import hu from "./hu";
import nl from "./nl";
import pl from "./pl";
import ro from "./ro";
import tr from "./tr";
import uk from "./uk";

export default {
  nl,
  en,
  pl,
  de,
  es,
  fr,
  tr,
  ro,
  bg,
  cs,
  uk,
  hu,
};
