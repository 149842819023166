import isEqual from "lodash/isEqual";

export function getDirtyInput<T>(
  isFieldDirty: (v: any) => boolean,
  input: T,
  returnAll = false,
): Partial<T> {
  if (returnAll) {
    return input;
  }
  // Loop over object and use isFieldDirty to determine wether to return it or not
  return Object.fromEntries(
    Object.entries(input as any).filter(([key]) =>
      isFieldDirty(key as unknown as keyof T),
    ),
  ) as Partial<T>;
}

export function arrayDirty<
  I extends {
    "@id": string
  },
  L extends {
    "@id"?: string
  },
>(initialItems: I[], items: L[], inputTransformer: (input: I) => L) {
  const created: L[] = [];
  const updated: (L & {
    "@id": string
  })[] = [];
  if (!items) {
    return {
      created: [],
      updated: [],
      deleted: [],
    };
  }
  const deleted: string[] = [];
  const ItemsIds = items.filter(item => item["@id"]).map(l => l["@id"]);
  const transformedInitialItems = initialItems.map(initItem =>
    inputTransformer(initItem),
  );
  for (const item of items) {
    if (item["@id"] === undefined) {
      created.push(item);
    } else {
      const initialLine = transformedInitialItems.find(
        transInitItem => transInitItem["@id"] === item["@id"],
      );
      if (initialLine === undefined) {
        continue;
      }
      // Now make new object with only keys
      const dirty = Object.entries(item).some(([key, value]) => {
        if (key === "@id") {
          return false;
        }
        const initItem = initialLine[key as keyof typeof initialLine];
        return !isEqual(value, initItem);
      });
      if (dirty && item["@id"]) {
        updated.push({ ...item, "@id": item["@id"] });
      }
    }
  }
  for (const initialLine of initialItems) {
    if (!ItemsIds.includes(initialLine["@id"])) {
      deleted.push(initialLine["@id"]);
    }
  }
  return {
    created,
    updated,
    deleted,
  };
}
