import type { RouteRecordRaw } from "vue-router";

export const AuthRoutes = <RouteRecordRaw[]>[
  {
    name: "login-root",
    path: "/",
    component: () => import("./AuthContainer.vue"),
    children: [
      {
        path: "",
        name: "entry",
        redirect: { name: "login" },
      },
      {
        path: "/:catchAll(.*)",
        redirect: { name: "login" },
      },
      {
        path: "login",
        name: "login",
        component: () => import("./Login/LoginView.vue"),
        meta: {
          public: true,
          hideNavbar: true,
          showTheme: true,
          showLanguage: true,
        },
      },
      {
        path: "choose-location",
        name: "choose-location",
        component: () => import("./Location/ChooseLocation.vue"),
        meta: {
          hideNavbar: true,
          showTheme: true,
          showLanguage: true,
        },
        // beforeEnter: async (_to, from, next) => {
        // if (!locationStore.defaultLocation.value) {
        //   console.log("No default location");
        //   next();
        // } else {
        //   if (from.name === "login" || from.name === "callback") {
        //     console.log("Redirect from login");
        //     const defaultLocation = locationStore?.locations?.value?.find(
        //       location => location["@id"] === locationStore.defaultLocation.value,
        //     );
        //     if (defaultLocation) {
        //       try {
        //         const locationId = defaultLocation["@id"].split("/").pop();
        //         const { data: locationData } = await api.get(`/api/locations/${locationId}`);
        //         const { data: clientData } = await api.get(locationData.client);

        //         authStore.setClient(clientData);

        //         locationStore.setSelectedLocation(defaultLocation["@id"]);
        //         next({ name: "tasks" });
        //       } catch (error) {
        //         console.error("Error fetching client data:", error);
        //         next();
        //       }
        //     } else {
        //       console.log("Default location not found");
        //       next();
        //     }
        //   } else {
        //     console.log("Redirect not from login");
        //     next();
        //   }
        // }
        // },
      },
      {
        path: "forgot-password",
        name: "forgot-password",
        component: () => import("./ForgotPassword/ForgotPassword.vue"),
        meta: {
          public: true,
          hideNavbar: true,
          showTheme: true,
          showLanguage: true,
        },
      },
      {
        path: "forgot-password/success",
        name: "forgot-password-success",
        component: () => import("./ForgotPassword/ForgotPasswordSuccess.vue"),
        meta: {
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "reset-password/:token",
        name: "reset-password",
        component: () => import("./ResetPassword/ResetPassword.vue"),
        meta: {
          public: true,
          hideNavbar: true,
          showTheme: true,
          showLanguage: true,
        },
      },
      {
        path: "reset-password/success",
        name: "reset-password-success",
        component: () => import("./ResetPassword/ResetPasswordSuccess.vue"),
        meta: {
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "reset-password/failed",
        name: "reset-password-failed",
        component: () => import("./ResetPassword/ResetPasswordFailed.vue"),
        meta: {
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "join/:hash",
        name: "join",
        component: () => import("./Verified/VerifiedView.vue"),
        meta: {
          public: true,
          hideNavbar: true,
          showTheme: true,
          showLanguage: true,
        },
      },
      {
        path: "impersonate/:clientId/:username",
        name: "impersonate",
        component: () => import("./Impersonate/ImpersonateView.vue"),
        meta: {
          skipAuthChecks: true,
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "oauth/v2/callback",
        name: "callback",
        component: () => import("@/features/Auth/Login/LoginCallback.vue"),
        meta: {
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "error",
        name: "error",
        component: () => import("./Login/LoginError.vue"),
        meta: {
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "oauth/callback/:type",
        name: "oauth-callback",
        component: () => import("./OAuth/CallbackView.vue"),
        meta: {
          skipAuthChecks: true,
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "oauth/callback/failed/:type",
        name: "oauth-callback-failed",
        component: () => import("./OAuth/CallbackFailed.vue"),
        meta: {
          skipAuthChecks: true,
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "oauth/callback/map-location/:type/:appSubscriptionId?",
        name: "oauth-callback-map-location",
        component: () => import("./OAuth/ChooseAdministration.vue"),
        meta: {
          skipAuthChecks: true,
          hideNavbar: true,
        },
      },
      {
        path: "oauth/callback/map-location/success/:type",
        name: "oauth-callback-map-location-success",
        component: () => import("./OAuth/ChooseAdministrationSuccess.vue"),
        meta: {
          skipAuthChecks: true,
          hideNavbar: true,
        },
      },
      {
        path: "oauth/callback/map-location/failed/:type",
        name: "oauth-callback-map-location-failed",
        component: () => import("./OAuth/ChooseAdministrationFailed.vue"),
        meta: {
          skipAuthChecks: true,
          hideNavbar: true,
        },
      },
    ],
  },
];

export default AuthRoutes;
