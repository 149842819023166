import { NotificationTypeEnum } from "@verbleif/lib";
import { ref } from "vue";

export const iconMap = {
  [NotificationTypeEnum.TYPE_REPORT_CREATED]: ["fas", "flag"],
  [NotificationTypeEnum.TYPE_SECURITY_LOGIN]: ["fas", "lock"],
  [NotificationTypeEnum.TYPE_TASK_ASSIGNED]: ["fas", "user"],
  [NotificationTypeEnum.TYPE_TASK_UNASSIGNED]: ["fas", "user"],
  [NotificationTypeEnum.TYPE_TASK_STATUS_CHANGED]: ["fas", "user"],
  [NotificationTypeEnum.TYPE_COMMENT_CREATED]: ["fas", "comment"],
  [NotificationTypeEnum.TYPE_TASK_CREATED]: ["fas", "ballot-check"],
  [NotificationTypeEnum.TYPE_APP_UPDATE]: ["fas", "redo"],
  [NotificationTypeEnum.TYPE_SYNC_BEX]: ["fas", "sync"],
  [NotificationTypeEnum.TYPE_SYNC_CAMPING_CARE]: ["fas", "sync"],
  [NotificationTypeEnum.TYPE_TASK]: ["fas", "ballot-check"],
  [NotificationTypeEnum.TYPE_SECURITY]: ["fas", "lock"],
  [NotificationTypeEnum.TYPE_REPORT]: ["fas", "flag"],
};

export interface NotificationItem {
  type: NotificationTypeEnum
  iri: string
  title: string
  leftFooterText?: string
  createdBy?: string | null
  hideAnimation?: boolean
  progressData?: ({
    current?: number | undefined
    max?: number | undefined
  }) | null
  closeable?: boolean
  autoClose?: boolean
  body?: string | null
  buttonAction?: (() => void) | null
  buttonText?: string | null
  duration?: number
}

function createNotifications() {
  const notifications = ref<NotificationItem[]>([]);

  function addStaticNotification(notification: NotificationItem) {
    const staticNotification = notifications.value.findIndex(
      n => n.iri === notification.iri,
    );

    const notificationObject: NotificationItem = {
      ...notification,
      closeable: false,
      autoClose: false,
    };

    // If exists, update existing.
    if (staticNotification !== -1) {
      notifications.value[staticNotification] = notificationObject;
      return;
    }

    notifications.value = [notificationObject, ...notifications.value];
  }

  function hideStaticNotification(notificationIri: string) {
    const staticNotification = notifications.value.findIndex(
      n => n.iri === notificationIri,
    );
    if (staticNotification !== -1) {
      notifications.value.splice(staticNotification, 1);
    }
  }

  function addNotification(notification: NotificationItem) {
    notifications.value = [...notifications.value, notification];
  }

  function hideNotification(index: number) {
    notifications.value.splice(index, 1);
  }

  return {
    hideStaticNotification,
    addStaticNotification,
    notifications,
    addNotification,
    hideNotification,
  };
}

export const notifications = createNotifications();
export default notifications;

export function useNotifications() {
  return notifications;
}
