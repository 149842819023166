import { useConfigStore } from "@verbleif/shared";

const config = useConfigStore();

config.setInitialConfig({
  presetName: import.meta.env.VITE_CONFIG_PRESET_NAME,
  apiUrl: import.meta.env.VITE_API_URL,
  appEnv: import.meta.env.VITE_ENV_NAME,
  mercureUrl: import.meta.env.VITE_MERCURE_URL,
  cdnUrl: import.meta.env.VITE_CDN_URL,
  loginUrl: import.meta.env.VITE_LOGIN_URL,
  loginClientId: import.meta.env.VITE_LOGIN_CLIENT_ID,
  loginRedirect: import.meta.env.VITE_LOGIN_REDIRECT,
});
