import router from "@/core/plugin/router";
import { useSystemStore } from "@/features/Store/useSystemStore";
import { useAuthStore, useConfigStore, useImpersonationStore, useLocationStore } from "@verbleif/shared";
import { Crisp } from "crisp-sdk-web";

export function useLogout() {
  const authStore = useAuthStore();
  const { config } = useConfigStore();
  const systemStore = useSystemStore();
  const locationStore = useLocationStore();
  const impersonationStore = useImpersonationStore();

  async function logout(redirectToLogin = true) {
    if (!config.value) {
      throw new Error("Config not set");
    }

    if (redirectToLogin) {
      authStore.reset();
      locationStore.reset();
      impersonationStore.reset();
      try {
        Crisp.session.reset();
        Crisp.chat.hide();
      } catch (e) {
        console.error(e);
      }
      if (import.meta.env.VITE_SKIP_OAUTH === "true") {
        await router.push({ name: "login" }).then(() => {
          systemStore.reset();
        });
      } else {
        window.open(`${config.value.loginUrl}/logout`, "_self");
      }
    }

    systemStore.reset();
  }

  return { logout };
}
