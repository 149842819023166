<script setup lang="ts">
import i18n from "@/core/plugin/i18n";
import { refreshToken } from "@/core/refresh";
import ConfigOverrideDialog from "@/features/ConfigOverride/ConfigOverrideDialog.vue";
import Loader from "@/features/Loader/LoaderView.vue";
import { useLoader } from "@/features/Loader/useLoader";
import NavbarContainer from "@/features/Navbar/NavbarContainer.vue";
import Notifications from "@/features/Notification/NotificationList.vue";
import NotificationSidebar from "@/features/NotificationSidebar/NotificationSidebar.vue";
import { useSystemStore } from "@/features/Store/useSystemStore";
import {
  DefaultConfirmDialog,
  provideConfirmDialog,
  provideDefaultConfirmDialog,
  provideNewConfirmDialog,
} from "@verbleif/lib";
import { api, useAuthStore, useLocationStore } from "@verbleif/shared";
import { Crisp } from "crisp-sdk-web";
import { getCurrentInstance, ref } from "vue";
import { setYupLocales } from "./core/plugin/yup";
import { useAppSync } from "./useAppSync";

const { t, locale } = useI18n();

watch(() => locale.value, () => {
  setYupLocales(t);
}, { immediate: true });

const route = useRoute();
const authStore = useAuthStore();
const systemStore = useSystemStore();
const locationStore = useLocationStore();
const loaderProvider = useLoader();
provideConfirmDialog();
const { syncAll } = useAppSync();
provideNewConfirmDialog();
try {
  Crisp.configure("3df3ab2f-fe2a-4c89-ad4a-43965e928246", {
    autoload: false,
    sessionMerge: true,
    locale: i18n.global.locale.value ?? "nl",
  });
} catch (e) {
  console.error(e);
}

const hideNavbar = computed(
  () => route.path === "/" || route.meta.hideNavbar === true,
);
const isPublicRoute = computed(
  () => route.path === "/" || route.meta.public === true,
);
const globalLoading = ref(true);

const confirmDialogRef = ref<InstanceType<typeof DefaultConfirmDialog>>();
const app = getCurrentInstance()?.appContext.app;

// Provide the confirm dialog instance immediately after setup
watch(confirmDialogRef, (value) => {
  if (value && app) {
    console.log("Providing confirm dialog", value);
    provideDefaultConfirmDialog(app, value);
  }
}, { immediate: true });

// Always reset the system store when switching locations.
watch([locationStore.selectedLocation, authStore.isAuthenticated], ([newLocation, isAuthenticated]) => {
  consola.debug("App.vue: locationStore.selectedLocation changed / immediate to: ", newLocation);
  if (!newLocation) {
    return;
  }

  systemStore.reset();
  if (isAuthenticated) {
    consola.debug("App.vue: loadApiBasics() since isAuthenticated is true.");
    syncAll();
  }
}, { immediate: true });

watchEffect(() => {
  if (!systemStore.selectedClient.value) {
    return;
  }
  api.get(
    `${systemStore.selectedClient.value["@id"]}/media_objects/avatars?pagination=false`,
  ).then(r => systemStore.setAvatars(r.data.member));
});

if (!authStore.hasToken.value && !authStore.isRemembered.value) {
  authStore.setUser(null); // Reset it to be sure.
  globalLoading.value = false;
  loaderProvider.hide();
}

if (authStore.hasToken.value || authStore.isRemembered.value) {
  refreshToken()
    .finally(() => {
      console.log("[DebugLoc] App.vue: refreshToken finished");
      globalLoading.value = false;
      loaderProvider.hide();
    });
}
watchEffect(() => {
  console.log("[DebugLoc] App.vue: globalLoading", globalLoading.value);
});

const loading = computed(() => loaderProvider.loading.value);
</script>

<template>
  <transition name="fade">
    <Loader v-if="loading || globalLoading" />
  </transition>
  <Notifications />
  <DefaultConfirmDialog ref="confirmDialogRef" />

  <template v-if="!globalLoading">
    <template v-if="!isPublicRoute && !hideNavbar">
      <NavbarContainer />
      <transition name="slide-in">
        <NotificationSidebar />
      </transition>
    </template>
    <div class="app-container" :class="{ 'is-login-route': !hideNavbar }">
      <router-view v-slot="{ Component }">
        <transition mode="out-in" name="fade">
          <component :is="Component" />
        </transition>
        <transition name="fade">
          <VConfirmDialog />
        </transition>
      </router-view>
      <VModalContainer />
      <ConfigOverrideDialog />
    </div>
  </template>
</template>

<style src="@verbleif/lib/src/scss/Reset.css"></style>

<style src="@verbleif/lib/src/scss/Fonts.css"></style>

<style src="@verbleif/lib/src/scss/Toast.css"></style>

<style lang="scss" src="./main.scss" />
