import activities from "./activities.json";
import auth from "./auth.json";
import base from "./base.json";
import csv from "./csv.json";
import dragAndDrop from "./dragAndDrop.json";
import locations from "./locations.json";
import navbar from "./navbar.json";
import notifications from "./notifications.json";
import objects from "./objects.json";

import reports from "./reports.json";
import reservations from "./reservations.json";
import roles from "./roles.json";
import settings from "./settings.json";
import tasks from "./tasks.json";
import thirdParty from "./thirdParty.json";
import users from "./users.json";
import validation from "./validation.json";

export default {
  base,
  roles,
  thirdParty,
  validation,
  settings,
  auth,
  tasks,
  objects,
  reports,
  notifications,
  dragAndDrop,
  csv,
  locations,
  navbar,
  users,
  reservations,
  activities,
};
