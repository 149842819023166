import type { Client, HydraItem, PropertyGroupHydraItem, PropertyHydraItem, ReportTopicHydraItem, SortHydraItem, UserGroupHydraItem, UserHydraItem } from "@verbleif/lib";
import { useSystemStore } from "@/features/Store/useSystemStore";
import { getAll, useLocationStore } from "@verbleif/shared";

export function useAppSync() {
  const { selectedLocationId } = useLocationStore();
  const {
    mode,
    setUserGroups,
    setProperties,
    setSorts,
    setUsers,
    setPropertyGroups,
    setClients,
    setReportTopics,
  } = useSystemStore();
  const isSyncRunning = ref(false);

  const params = computed<URLSearchParams | undefined>(() => {
    const params = new URLSearchParams();
    if (mode.value === "all") {
      return params;
    }
    if (mode.value === "single" && selectedLocationId.value) {
      params.set("location", `${selectedLocationId.value}`);
      return params;
    }
    throw new Error("[useAppSync] No location selected");
  });

  async function syncUserGroups() {
    const userGroups = await getAll<UserGroupHydraItem>({
      url: "/user_groups",
      initialParams: params.value,
    });
    setUserGroups(userGroups);
  };

  async function syncProperties() {
    const properties = await getAll<PropertyHydraItem>({
      url: "/properties",
      initialParams: params.value,
    });
    setProperties(properties);
  }

  async function syncSorts() {
    const sorts = await getAll<SortHydraItem>({
      url: "/sorts",
      initialParams: params.value,
    });
    setSorts(sorts);
  }

  async function syncUsers() {
    const users = await getAll<UserHydraItem>({
      url: "/users",
      initialParams: params.value,
    });
    setUsers(users);
  }

  async function syncPropertyGroups() {
    const propertyGroups = await getAll<PropertyGroupHydraItem>({
      url: "/property_groups",
      initialParams: params.value,
    });
    setPropertyGroups(propertyGroups);
  }

  async function syncClients() {
    const clients = await getAll<HydraItem<Client>>({
      url: "/clients",
      initialParams: params.value,
    });
    setClients(clients);
  }

  async function syncReportTopics() {
    const reportTopics = await getAll<ReportTopicHydraItem>({
      url: "/report_topics",
      initialParams: params.value,
    });
    setReportTopics(reportTopics);
  }

  async function syncAll() {
    if (isSyncRunning.value) {
      return;
    }
    try {
      isSyncRunning.value = true;
      syncUsers().then();
      syncUserGroups().then();
      syncSorts().then();
      syncProperties().then();
      syncPropertyGroups().then();
      syncClients().then();
      syncReportTopics().then();
    } finally {
      isSyncRunning.value = false;
    }
  }

  return {
    syncAll,
  };
}
