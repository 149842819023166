import type {
  Client,
  HydraItem,
  LocationHydraItem,
  UserHydraItem,
} from "@verbleif/lib";
import type { AuthResponse } from "@verbleif/shared";
import type { AxiosRequestConfig } from "axios";
import router from "@/core/plugin/router";
import * as Sentry from "@sentry/vue";
import {
  api,
  getAll,
  useAuthStore,
  useLocationStore,
} from "@verbleif/shared";
import { Crisp } from "crisp-sdk-web";

export async function onAuthenticateSuccess(response: AuthResponse, isLogin: boolean = false): Promise<void> {
  const authStore = useAuthStore();
  const locationStore = useLocationStore();
  const systemStore = useSystemStore();
  // Set tokens
  authStore.setToken(response.access_token);
  authStore.setRefreshToken(response.refresh_token, true);

  // Fetch and set user
  const user = await api.get<UserHydraItem>("/api/users/me", {
    _retry: false,
  } satisfies AxiosRequestConfig & { _retry: boolean } as any)
    .then(r => r.data);
  authStore.setUser(user);

  const clients = await getAll<HydraItem<Client>>({
    url: "/clients",
    _retry: false,
  });
  systemStore.setClients(clients);

  const locations = await getAll<LocationHydraItem>({
    url: "/locations",
    _retry: false,
  });
  locationStore.setLocations(locations);
  try {
    if (authStore.user.value) {
      if (authStore.user.value?.username && authStore.user.value?.username.includes("@")) {
        console.log("Setting email to crisp", authStore.user.value);
        Crisp.user.setEmail(authStore.user.value?.username);
      }
      if (authStore.user.value?.name) {
        Crisp.user.setNickname(authStore.user.value?.name);
      }
      if (systemStore.selectedClient.value?.name) {
        Crisp.user.setCompany(systemStore.selectedClient.value?.name, {});
      }
      Crisp.session.setData({
        user_id: authStore.user.value?.id,
      });
    }
    Crisp.session.setSegments(["web"], false);
    Crisp.chat.show();
  } catch (e) {
    Sentry.captureException(e);
    console.error("Crisp:", e);
  }

  if (isLogin) {
    const currentRoute = router.currentRoute.value;
    if (
      locationStore.hasMultipleLocations.value
      && !locationStore.selectedLocation.value
      && !currentRoute.meta.public
    ) {
      await router.push({ name: "choose-location" });
    }

    if (locations.length === 1) {
      // Automatically select the single location and proceed
      locationStore.setSelectedLocation(locations[0]["@id"]);
      await router.push({ name: "tasks" });
    }
  }
}
