import type { Client, ClientHydraItem, HydraItem, MediaObject, PropertyGroupHydraItem, PropertyHydraItem, ReportTopicHydraItem, SortHydraItem, UserGroupHydraItem, UserHydraItem } from "@verbleif/lib";
import { useRightsStore } from "@/core/store/RightStore";
import { SortFieldEnum } from "@verbleif/lib";
import { api, Feature, useAuthStore, useLocationStore, UserRole } from "@verbleif/shared";
import localforage from "localforage";
import { computed, reactive, ref, watchEffect } from "vue";

const systemStoreIndexedDb: LocalForage = localforage.createInstance({
  driver: localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
  name: "verbleif_system_store",
  version: 1.0,
  storeName: "verbleif_system_store", // Should be alphanumeric, with underscores.
  description: "verbleif storage",
});
function createSystemStore() {
  const mode = ref<"single" | "all">("single");
  const users = ref<UserHydraItem[]>([]);
  const clients = ref<HydraItem<Client>[]>([]);
  const systemUsers = ref<UserHydraItem[]>([]);
  const userGroups = ref<UserGroupHydraItem[]>([]);
  const sorts = ref<SortHydraItem[]>([]);
  const propertyGroups = ref<PropertyGroupHydraItem[]>([]);
  const properties = ref<PropertyHydraItem[]>([]);
  const avatars = ref<HydraItem<MediaObject>[]>([]);
  const reportTopics = ref<ReportTopicHydraItem[]>([]);
  const searchValue = ref<string>("");
  const searchTypes = reactive<Record<"UserGroup" | "User", boolean>>({ UserGroup: true, User: true });
  const debugMode = ref<boolean>(false);
  const taskSidebar = reactive<Record<string, any>>({});
  const reportSidebar = reactive<Record<string, any>>({});
  const { hasRole, hasRoleStrict, hasFeature } = useRightsStore();
  const { selectedLocationObject } = useLocationStore();
  const overviewSettings = ref({
    showStatusDone: JSON.parse(localStorage.getItem("showStatusDone") ?? "true"),
    showNoDeadline: JSON.parse(localStorage.getItem("showNoDeadline") ?? "true"),
    showStatusCompleted: JSON.parse(localStorage.getItem("showStatusCompleted") ?? "true"),
    hideSearchAndSort: JSON.parse(localStorage.getItem("hideSearchAndSort") ?? "true"),
  });

  // Hydrate from external store
  watch(debugMode, () => systemStoreIndexedDb.setItem("debugMode", debugMode.value));
  systemStoreIndexedDb.getItem("debugMode", (err, value: any) => {
    if (err) {
      debugMode.value = false;
      return;
    }
    debugMode.value = !!value;
  });
  watch(taskSidebar, () => systemStoreIndexedDb.setItem("taskSidebar", JSON.stringify(taskSidebar)));
  systemStoreIndexedDb.getItem("taskSidebar", (err, value: any) => {
    if (err) {
      return;
    }
    if (typeof value !== "string") {
      value = "{}";
    }
    Object.assign(taskSidebar, JSON.parse(value));
  });
  watch(reportSidebar, () => systemStoreIndexedDb.setItem("reportSidebar", JSON.stringify(reportSidebar)));
  systemStoreIndexedDb.getItem("reportSidebar", (err, value: any) => {
    if (err) {
      return;
    }
    if (typeof value !== "string") {
      value = "{}";
    }
    Object.assign(reportSidebar, JSON.parse(value));
  });
  watch(searchTypes, () => systemStoreIndexedDb.setItem("searchTypes_1", JSON.stringify(searchTypes)));
  systemStoreIndexedDb.getItem("searchTypes_1", (err, value: any) => {
    if (err) {
      return;
    }
    if (typeof value !== "string") {
      value = "{}";
    }
    Object.assign(searchTypes, JSON.parse(value));
  });

  watchEffect(() => {
    localStorage.setItem("showStatusDone", overviewSettings.value.showStatusDone.toString());
    localStorage.setItem("showNoDeadline", overviewSettings.value.showNoDeadline.toString());
    localStorage.setItem("showStatusCompleted", overviewSettings.value.showStatusCompleted.toString());
    localStorage.setItem("hideSearchAndSort", overviewSettings.value.hideSearchAndSort.toString());
  });

  // Computed Properties
  const avatarsUrlIndexedByIri = computed<Record<string, string>>(() => {
    return avatars.value.reduce((acc, obj) => {
      const { "@id": key, contentUrl } = obj;
      acc[key] = contentUrl;
      return acc;
    }, {} as Record<string, string>);
  });
  const avatarsMediaObjectIndexedByIri = computed<Record<string, HydraItem<MediaObject>>>(() => {
    return avatars.value.reduce((acc, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {} as Record<string, HydraItem<MediaObject>>);
  });
  const usersIndexedByIri = computed<Record<string, UserHydraItem>>(() => {
    return users.value.reduce((acc, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {} as Record<string, UserHydraItem>);
  });

  const clientsIndexedByIri = computed<Record<string, HydraItem<Client>>>(() => {
    return clients.value.reduce((acc, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {} as Record<string, HydraItem<Client>>);
  });

  const selectedClient = computed<ClientHydraItem | null>(() => {
    if (!selectedLocationObject.value) {
      return null;
    }
    const clientIri = selectedLocationObject.value.client;

    if (!clientIri) {
      return null;
    }

    return clientsIndexedByIri.value[clientIri] || null;
  });
  const systemUsersIndexedByIri = computed<Record<string, UserHydraItem>>(() => {
    return systemUsers.value.reduce((acc, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {} as Record<string, UserHydraItem>);
  });
  const userGroupsIndexedByIri = computed<Record<string, UserGroupHydraItem>>(() => {
    return userGroups.value.reduce((acc, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {} as Record<string, UserGroupHydraItem>);
  });
  const sortsIndexedByIri = computed<Record<string, SortHydraItem>>(() => {
    return sorts.value.reduce((acc, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {} as Record<string, SortHydraItem>);
  });

  const reportTopicsIndexedByIri = computed<Record<string, ReportTopicHydraItem>>(() => {
    return reportTopics.value.reduce((acc, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {} as Record<string, ReportTopicHydraItem>);
  });

  function updateSort(sort: SortHydraItem) {
    sorts.value = sorts.value.map(s => s["@id"] === sort["@id"] ? sort : s);
  }
  const propertyGroupsIndexedByIri = computed<Record<string, PropertyGroupHydraItem>>(() => {
    return propertyGroups.value.reduce((acc: Record<string, PropertyGroupHydraItem>, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {});
  });
  const propertiesIndexedByIri = computed<Record<string, PropertyHydraItem>>(() => {
    return properties.value.reduce((acc: Record<string, PropertyHydraItem>, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {});
  });

  const frontdeskUsers = computed(() => {
    return users.value.filter((user) => {
      return hasRole(UserRole.ROLE_FRONT_DESK, user);
    });
  });

  const usersBasedOnRole = computed(() => {
    const authStore = useAuthStore();

    if (!authStore.user.value) {
      return [];
    }

    const currentUser = authStore.user.value;

    if (
      hasRole(UserRole.ROLE_PARK_MANAGER)
      || hasRoleStrict(UserRole.ROLE_FRONT_DESK)
      || hasRoleStrict(UserRole.ROLE_DEPARTMENT_MANAGER)
    ) {
      return users.value;
    }

    const currentUserGroups = api.get(`/users/${currentUser.id}/user_groups`).then((response) => {
      return response.data.member;
    });

    const currentUserManagerGroups = api.get(`/users/${currentUser.id}/manager_user_groups`).then((response) => {
      return response.data.member;
    });

    const requiredGroups: string[] = [];
    if (currentUserGroups) {
      currentUserGroups.then((groups) => {
        groups.forEach((group: { "@id": string }) => {
          requiredGroups.push(group["@id"]);
        });
      });
    }

    if (hasRoleStrict(UserRole.ROLE_DEPARTMENT_MANAGER) && currentUserManagerGroups) {
      currentUserManagerGroups.then((groups) => {
        groups.forEach((group: { "@id": string }) => {
          requiredGroups.push(group["@id"]);
        });
      });
    }

    return users.value.filter((user) => {
      if (hasRole(UserRole.ROLE_PARK_MANAGER, user)) {
        return true;
      }

      const userGroups = api.get(`/users/${user.id}/user_groups`).then((response) => {
        return response.data.member;
      });

      let inGroup = false;
      const inManagerGroup = false;

      if (userGroups) {
        userGroups.then((groups) => {
          groups.forEach((group: { "@id": string }) => {
            if (requiredGroups.includes(group["@id"])) {
              inGroup = true;
            }
          });
        });
      }

      const isCurrentUser = currentUser?.id === user.id;
      return inGroup || inManagerGroup || isCurrentUser;
    });
  });
  const assignableUsersTeamsDepartments = computed(() => {
    const defaultAssignableAssignees = [
      ...userGroups.value,
      ...usersBasedOnRole.value,
    ];

    if (hasFeature(Feature.ANY_CAN_ASSIGN_TO_FRONTDESK)) {
      defaultAssignableAssignees.push(...frontdeskUsers.value);
    }

    return defaultAssignableAssignees.sort((a: UserHydraItem | UserGroupHydraItem, b: UserHydraItem | UserGroupHydraItem) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  });
  const filteredAssignableUserGroupsUsers = computed(() => {
    return assignableUsersTeamsDepartments.value.filter((item) => {
      const name: "UserGroup" | "User" = item["@type"] as "UserGroup" | "User";
      const isTypeEnabled: boolean = searchTypes[name];

      if (!isTypeEnabled) {
        return false;
      }

      if (searchValue.value === "") {
        return true;
      }

      if ("name" in item) {
        return item.name.toLowerCase().includes(searchValue.value.toLowerCase());
      }

      return true;
    });
  });

  // Methods
  function reset() {
    users.value = [];
    userGroups.value = [];
    sorts.value = [];
    clients.value = [];
    propertyGroups.value = [];
    properties.value = [];
    avatars.value = [];
    searchValue.value = "";
    overviewSettings.value = {
      showStatusDone: true,
      showNoDeadline: true,
      showStatusCompleted: true,
      hideSearchAndSort: true,
    };
  }

  function setUsers(newUsers: UserHydraItem[]) {
    users.value = newUsers;
  }
  function setSystemUsers(newUsers: UserHydraItem[]) {
    systemUsers.value = newUsers;
  }
  function setPropertyGroups(newPropertyGroups: PropertyGroupHydraItem[]) {
    propertyGroups.value = newPropertyGroups;
  }
  function setProperties(newProperties: PropertyHydraItem[]) {
    properties.value = newProperties;
  }
  function setSorts(newSorts: SortHydraItem[]) {
    sorts.value = newSorts;
  }
  function setUserGroups(newUserGroups: UserGroupHydraItem[]) {
    userGroups.value = newUserGroups;
  }
  function setClients(newClients: HydraItem<Client>[]) {
    clients.value = newClients;
  }
  function setSearchValue(value: string) {
    searchValue.value = value;
  }
  function toggleSearchType(type: "UserGroup" | "User") {
    searchTypes[type] = !searchTypes[type];
  }
  function setAvatars(newAvatars: HydraItem<MediaObject>[]) {
    avatars.value = newAvatars;
  }

  function setReportTopics(newReportTopics: ReportTopicHydraItem[]) {
    reportTopics.value = newReportTopics;
  }

  const hasOneSort = computed(() => {
    return sorts.value.length === 1;
  });

  const hasInsideOnASort = computed<boolean>(() => {
    return sorts.value.some((sort) => {
      return sort.visibleFields?.includes(SortFieldEnum.INSIDE);
    });
  });

  const hasQuantityOnASort = computed<boolean>(() => {
    return sorts.value.some((sort) => {
      return sort.visibleFields?.includes(SortFieldEnum.QUANTITY);
    });
  });

  const hasPriorityOnASort = computed<boolean>(() => {
    return sorts.value.some((sort) => {
      return sort.visibleFields?.includes(SortFieldEnum.PRIORITY);
    });
  });

  return {
    // State
    mode,
    users,
    userGroups,
    sorts,
    properties,
    propertyGroups,
    avatars,
    clients,
    searchValue,
    searchTypes,
    debugMode,
    updateSort,
    taskSidebar,
    reportSidebar,
    hasOneSort,
    overviewSettings,
    reportTopics,
    // Computed Properties
    avatarsUrlIndexedByIri,
    avatarsMediaObjectIndexedByIri,
    usersIndexedByIri,
    systemUsersIndexedByIri,
    userGroupsIndexedByIri,
    sortsIndexedByIri,
    propertyGroupsIndexedByIri,
    propertiesIndexedByIri,
    clientsIndexedByIri,
    usersBasedOnRole,
    assignableUsersTeamsDepartments,
    filteredAssignableUserGroupsUsers,
    selectedClient,
    hasInsideOnASort,
    hasQuantityOnASort,
    hasPriorityOnASort,
    reportTopicsIndexedByIri,
    // Methods
    reset,
    setUsers,
    setSystemUsers,
    setPropertyGroups,
    setProperties,
    setSorts,
    setUserGroups,
    setClients,
    setSearchValue,
    toggleSearchType,
    setAvatars,
    setReportTopics,
  };
}

type SystemStoreInterface = ReturnType<typeof createSystemStore>;
let backwardsCompatibleInstance: null | SystemStoreInterface = null;
export function useSystemStore(): SystemStoreInterface {
  if (backwardsCompatibleInstance === null) {
    backwardsCompatibleInstance = createSystemStore();
  }

  return backwardsCompatibleInstance;
}
export default useSystemStore;
