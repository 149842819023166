<script setup lang="ts">
import type { HydraItem, Notification } from "@verbleif/lib";
import { iconMap } from "@/features/Notification/useNotifications";
import { useNotificationSidebar } from "@/features/NotificationSidebar/useNotificationSidebar";
import moment from "moment";
import { computed, toRefs } from "vue";
import { useI18n } from "vue-i18n";

defineOptions({
  name: "NotificationSidebarItem",
});

const props = defineProps<{
  notification: HydraItem<Notification>
}>();

const { removeNotification, markAsRead } = useNotificationSidebar();
const { t } = useI18n();
const { notification } = toRefs(props);

const read = computed(() => {
  return notification.value.readAt !== null;
});
const icon = computed(() => {
  return iconMap[notification.value.type];
});

const date = moment(notification.value.createdAt).fromNow();
</script>

<template>
  <li class="notification-item">
    <div class="content">
      <header class="header">
        <FontAwesomeIcon
          :class="{ read }"
          :icon="icon"
          class="icon-type"
        />
        <h1
          :class="{ read }"
          :title="t(notification.title)"
          class="title"
        >
          {{ t(notification.title) }}
        </h1>
      </header>
      <main class="main">
        <p :class="{ read }" class="message">
          {{ notification.messageWeb }}
        </p>
      </main>
      <footer class="footer">
        <p :class="{ read }" class="when">
          {{ date }}
        </p>
      </footer>
    </div>
    <div class="icons">
      <FontAwesomeIcon
        :class="{ read }"
        :icon="['fal', 'times']"
        class="icon-close"
        @click="removeNotification(notification)"
      />
      <FontAwesomeIcon
        v-if="!read"
        :icon="['far', 'circle-check']"
        class="icon-confirmation"
        @click="markAsRead(notification)"
      />
    </div>
  </li>
</template>

<style lang="scss" scoped>
.notification-item {
  min-width: 274px;
  min-height: 100px;
  width: 274px;
  height: 100px;
  max-width: 274px;
  max-height: 100px;
  overflow: hidden;
  display: flex;
  border-radius: 4px;
  border: 1px #ebebeb solid;
  padding: 10px;

  &:not(&:last-child) {
    margin-bottom: 10px;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;

    .header {
      width: 100%;
      height: 14px;
      display: flex;
      align-items: center;

      .icon-type {
        color: var(--text);
        font-size: 12px;
        font-weight: 400;
        margin-right: 10px;

        &.read {
          color: var(--text-2);
        }
      }

      .title {
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        color: var(--text);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &.read {
          color: var(--text-2);
        }
      }

      margin-bottom: 8px;
    }

    .main {
      width: 100%;
      height: 100%;

      .message {
        font-size: 10px;
        font-weight: 400;
        color: var(--text);
        margin-bottom: 8px;

        &.read {
          color: var(--text-2);
        }
      }

      .who {
        font-size: 10px;
        font-weight: 700;
        color: var(--text);

        &.read {
          color: var(--text-2);
        }

        .what {
          font-weight: 400;

          &.read {
            color: var(--text-2);
          }
        }
      }

      margin-bottom: 9px;
    }

    .footer {
      width: 100%;
      height: 14px;
      margin-top: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .when {
        margin-top: auto;
        color: var(--text-2);
        font-size: 11px;
        font-weight: 700;

        &.read {
          color: var(--text-2);
        }
      }
    }
  }

  .icons {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    font-size: 16px;
    color: var(--text);
    justify-content: space-between;
    align-items: center;

    .icon-close,
    .icon-confirmation {
      display: flex;
      cursor: pointer;
    }

    .icon-confirmation {
      opacity: 0;
      transition: 250ms opacity ease;
    }
  }

  &:hover .icons .icon-confirmation {
    opacity: 1;
  }
}
</style>
