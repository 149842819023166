import { setLocale } from "yup";

export function setYupLocales(t: (v: string, args?: any) => string) {
  setLocale({
    mixed: {
      default: t("base.forms.invalid"),
      required: t("base.forms.required"),
      notNull: t("base.forms.required"),
      oneOf: t("base.forms.required"),
      notOneOf: t("base.forms.required"),
      notType: t("base.forms.required"),
      // Add more default error messages for mixed type validations
    },
    string: {
      email: t("base.forms.email"),

      // Add more default error messages for string type validations
    },
    array: {
      min: min => t("base.forms.required", { min }),
      max: max => t("base.forms.required", { max }),
    },
    number: {
      min: min => t("base.forms.min", { min }),
      max: max => t("base.forms.max", { max }),
      positive: t("base.forms.positive"),
      // Add more default error messages for number type validations
    },
  });
}
