import type { RouteRecordRaw } from "vue-router";

export enum TaskListType {
  TYPE_TODAY = "td",
  TYPE_ALL_TODAY = "atd",
  TYPE_PER_OBJECT = "po",
  TYPE_ALL = "a",
  TYPE_ALL_UNTIL_TODAY = "atud",
  TYPE_OPEN_UNTIL_TODAY = "oto",
  TYPE_CHANGEOVER_DAY = "co",
  TYPE_ASSIGNED_TO_ME = "asg",
  TYPE_ROUTE = "ro",
  TYPE_UNASSIGNED = "uasg",
  TYPE_READY = "rd",
  TYPE_FINISHED = "fn",
  TYPE_USER_GROUP = "ug",
  TYPE_USER = "u",
  TYPE_CUSTOM = "c",
  TYPE_TEMPLATE = "tp",
}

const FALLBACK_TYPE = TaskListType.TYPE_TODAY;

export const routes = <RouteRecordRaw[]>[
  {
    path: "/t",
    name: "tasks",
    component: () => import("@/features/Tasks/TasksContainer.vue"),
    redirect: (props) => {
      return {
        name: "task-list",
        params: { type: FALLBACK_TYPE, ...props?.params },
      };
    },
    children: [
      {
        path: "/:catchAll(.*)",
        redirect: {
          name: "task-list",
          params: { type: FALLBACK_TYPE },
        },
      },
      {
        path: "li/:type/:id?/:taskId?",
        name: "task-list",
        component: () => import("./Overview/TasksView.vue"),
        beforeEnter: (to, _, next) => {
          const type = <TaskListType>to.params.type;
          if (!Object.values(TaskListType).includes(type)) {
            return next({
              name: "task-list",
              params: { type: FALLBACK_TYPE },
            });
          }
          return next();
        },
      },
    ],
  },
];

export default routes;
