import AuthRoutes from "@/features/Auth/AuthRoutes";
import PublicRoutes from "@/features/Public/PublicRoutes";
import ReportRoutes from "@/features/Reports/ReportsRoutes";
import SettingsRoutes from "@/features/Settings/SettingsRoutes";
import TaskRoutes from "@/features/Tasks/TasksRoutes";
import { useAuthStore, useLocationStore } from "@verbleif/shared";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  ...SettingsRoutes,
  ...AuthRoutes,
  ...TaskRoutes,
  ...ReportRoutes,
  ...PublicRoutes,
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});
export default router;

const { returnUrl, hasToken, isRemembered } = useAuthStore();
const locationStore = useLocationStore();

router.beforeEach((to, _, next) => {
  const name = <string>to.name;
  const isPublicRoute = name === "impersonate"
    || name === "public_report"
    || name.startsWith("oauth-callback");

  if (isPublicRoute) {
    return next();
  }

  const isUnauthenticated = !hasToken.value && !isRemembered.value;
  if (isUnauthenticated && !to.meta.public) {
    returnUrl.value = to.fullPath;
    return next({ name: "login" });
  }

  const fallback = to.params.redirect as string
    || to.query.redirect as string
    || { name: "tasks" };
  console.log("fallback", fallback);

  // console.log("locationStore.selectedLocation.value", locationStore.selectedLocation.value);

  // if (to.name === "choose-location" && !locationStore.hasMultipleLocations.value) {
  //   return next(fallback);
  // }

  const isAuthenticated = hasToken.value || isRemembered.value;
  if (isAuthenticated && to.meta.public) {
    return next(fallback);
  }

  if (to.name !== "choose-location" && isAuthenticated) {
    if (!locationStore.selectedLocation.value) {
      return next({ name: "choose-location" });
    }
  }

  next();
});
