import type { NotificationItem } from "@/features/Notification/useNotifications";
import type { ConfirmDialogOptions, useConfirmDialog } from "@verbleif/lib";
import { useI18n } from "@/core/plugin/i18n";
import { useNotifications } from "@/features/Notification/useNotifications";
import { NotificationTypeEnum } from "@verbleif/lib";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from "firebase/messaging";
import { ref, watchEffect } from "vue";

const VAPID_KEY = "BNP0kOXWCJWv3gnQvHZ10GzqtwVdedjSa0kKeOP1IjDmeQZZ_6k2zrm__ONfqUACzeL0mHJOdnleqLu2HZeAlU4";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCOWQ4W4e9K544Itbts_gsTpGc-AEIgVsg",
  authDomain: "verbleif-489a4.firebaseapp.com",
  databaseURL: "https://verbleif-489a4.firebaseio.com",
  projectId: "verbleif-489a4",
  storageBucket: "verbleif-489a4.appspot.com",
  messagingSenderId: "572754758664",
  appId: "1:572754758664:web:d0a4899006b1f49aa5d5bd",
  measurementId: "G-MBT9BYGQJQ",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const messaging = isSupported().then((supported) => {
  if (!supported) {
    return getMessaging(app);
  }
  return null;
});

export function createFirebase() {
  const { t } = useI18n();
  const notifications = useNotifications();
  const granted = ref(
    window.Notification && window.Notification.permission === "granted",
  );
  const user = ref();

  function setUser(v: any) {
    user.value = v;
  }

  async function initialize(dialog: ReturnType<typeof useConfirmDialog>) {
    if (await shouldRequestNotificationPermission()) {
      granted.value = await requestPermission(dialog)
        .then(() => true)
        .catch(() => false);
    }

    if (granted.value) {
      return await getToken(getMessaging(), { vapidKey: VAPID_KEY });
    }

    return new Promise((_resolve, reject) => reject(new Error("Firebase initialize failed.")));
  }

  async function shouldRequestNotificationPermission() {
    if (!window.Notification) {
      return false;
    }
    if (!(await isSupported())) {
      return false;
    }
    return window.Notification.permission === "default";
  }

  async function requestPermission(dialog: ReturnType<typeof useConfirmDialog>) {
    return new Promise((resolve, reject) => {
      const options: ConfirmDialogOptions = {
        title: t("notifications.setup_enable_title"),
        messageIcon: ["far", "bell"],
        message: t("notifications.setup_enable_description"),
        continueIcon: ["fas", "bell"],
        cancelIcon: false,
        continueText: t("notifications.setup_enable_continue"),
        cancelText: t("notifications.setup_enable_cancel"),
        continueVariant: "is-primary",
        cancelVariant: "is-light",
        shouldConfirm: false,
        onContinue: () => {
          resolve(true);
          dialog.setLoading(false);
          dialog.close();
        },
        onCancel: () => {
          reject(new Error("notification.later"));
          dialog.setLoading(false);
          dialog.close();
        },
      };
      dialog.open({ options });
    });
  }

  watchEffect(() => {
    if (!granted.value) {
      return;
    }

    onMessage(getMessaging(), (payload) => {
      const notification = payload.notification;
      const data = payload.data;

      if (!notification) {
        console.error(payload);
        throw new Error("Invalid payload.notification received from firebase.");
      }
      if (!data) {
        console.error(payload);
        throw new Error("Invalid payload.data received from firebase.");
      }

      if ("target" in data) {
        if (data.target !== user.value["@id"]) {
          return;
        }
      }

      if (!("title" in notification)) {
        console.error(data);
        throw new Error("Invalid payload.notification.title received from firebase.");
      }

      if (!("body" in notification)) {
        console.error(payload);
        throw new Error("Invalid payload.notification.body received from firebase.");
      }

      if (!("@id" in data)) {
        console.error(payload);
        throw new Error("Invalid payload.notification.body received from firebase.");
      }

      const type = data?.type;
      if (!type || !(Object.values(NotificationTypeEnum) as string[]).includes(type)) {
        console.error("Invalid data.type received from firebase:", type);
        return;
      }
      const enumType = type as NotificationTypeEnum;

      if (!notification?.title || typeof notification.title !== "string") {
        console.error("Invalid payload.notification.title received from firebase:", data);
        return;
      }

      const mappedData: NotificationItem = {
        title: notification.title,
        body: notification.body,
        type: enumType,
        iri: data["@id"],
      };

      notifications.addNotification(mappedData);
    });
  });

  return {
    setUser,
    initialize,
    requestPermission,
    isSupported,
  };
}

export const firebase = createFirebase();
export default firebase;

export function useFirebase() {
  return firebase;
}
