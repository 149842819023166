import type { AppConfig } from "@verbleif/shared";

export type EnvironmentKey = keyof typeof config;

export const config = <Record<string, AppConfig>>{
  localhost: {
    apiUrl: "https://api.verbleif.dev",
    mercureUrl: "https://mercure.verbleif.localhost:81",
    cdnUrl: "https://cdn.verbleif.localhost",
    appEnv: "local",
    loginUrl: "https://login.verbleif.dev",
    loginRedirect: "http://app.verbleif.localhost:5173",
    loginClientId: "f3bee0a0f13225abc6216d29ffce28a5",
  },
  testing: {
    apiUrl: "https://api.verbleif.dev",
    mercureUrl: "https://mercure.verbleif.dev",
    loginUrl: "https://login.verbleif.dev",
    loginRedirect: "http://app.verbleif.dev",
    cdnUrl: "https://cdn.verbleif.dev",
    appEnv: "dev",
    loginClientId: "f3bee0a0f13225abc6216d29ffce28a5",
  },
  staging: {
    apiUrl: "https://api.staging.verbleif.com",
    mercureUrl: "https://mercure.staging.verbleif.com",
    loginUrl: "https://login.staging.verbleif.com",
    loginRedirect: "http://app.staging.verbleif.com",
    loginClientId: "f3bee0a0f13225abc6216d29ffce28a5",
    cdnUrl: "https://cdn.staging.verbleif.com",
    appEnv: "staging",
  },
  production: {
    apiUrl: "https://api.verbleif.com",
    mercureUrl: "https://mercure.verbleif.com",
    loginUrl: "https://login.verbleif.com",
    loginRedirect: "http://app.verbleif.com",
    loginClientId: "f3bee0a0f13225abc6216d29ffce28a5",
    cdnUrl: "https://cdn.verbleif.com",
    appEnv: "production",
  },
};
