<script setup lang="ts">
import { useConfigStore } from "@verbleif/shared";
import { useMagicKeys } from "@vueuse/core";
import { ref, watch } from "vue";
import FormDebugContent from "./FormDebugContent.vue";

defineProps<{
  hideTrigger?: boolean
}>();

defineSlots<{
  default?: void
  customTab?: void
  customTabItems?: void
  customTabPanels?: void
}>();

const visible = ref(false);
const { debugMode } = useConfigStore();

function show() {
  visible.value = true;
}

const { Shift_D } = useMagicKeys();
watch(() => Shift_D.value, (value) => {
  if (value && debugMode.value) {
    show();
  }
});

defineExpose({
  show,
});
</script>

<template>
  <div v-if="debugMode">
    <div
      v-if="!hideTrigger"
      class="trigger"
      @click="visible = true"
    >
      <template v-if="$slots.default">
        <slot />
      </template>
      <VButton
        v-else
        rounded
        class="is-grey"
      >
        <template #icon>
          <i class="fas fa-bug mr-2" />
        </template>
        Debug
      </VButton>
    </div>
    <VDialog
      id="form-debug-dialog"
      :visible="visible"
      mask
      size="xlarge"
      @close="visible = false"
    >
      <template #title>
        <strong class="text-lg">Vee-validate Debug</strong>
      </template>
      <template #content>
        <FormDebugContent>
          <template v-if="$slots.customTab" #customTab>
            <slot name="customTab" />
          </template>
          <template v-if="$slots.customTabItems" #customTabItems>
            <slot name="customTabItems" />
          </template>
          <template v-if="$slots.customTabPanels" #customTabPanels>
            <slot name="customTabPanels" />
          </template>
        </FormDebugContent>
      </template>
    </VDialog>
  </div>
  <template v-else>
    <slot />
  </template>
</template>

<style scoped lang="scss">
.trigger {
  :deep(*) {
    transition: 0.15s color;
  }
  &:hover :deep(*) {
    cursor: pointer;
    color: orange !important;
    transition: 0.15s color;
  }
}
</style>
